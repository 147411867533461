import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Blockchain Solutions',
        description: 'Empowering Your Future with Blockchain. From smart contracts to decentralized applications (DApps), our blockchain offerings are designed to optimize processes, enhance security, and foster innovation.'
    },
    {
        icon: '02',
        title: 'Smart Contracts',
        description: 'Elevate the efficiency of your transactions with our smart contract solutions. Automate agreements, reduce risks, and streamline processes using blockchain-based contracts.'
    },
    {
        icon: '03',
        title: 'DApps',
        description: 'Experience the power of decentralized applications. Our DApps leverage blockchain technology to provide secure, transparent, and tamper-resistant solutions for a variety of industries.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Blockchain Technologies"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;