import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Artificial Intelligence (AI) and Machine Learning (ML)',
        description: 'Continued advancements in AI and ML are transforming various industries. Deep learning, neural networks, and reinforcement learning are some of the key areas of focus.'
    },
    {
        icon: <FiLayers />,
        title: 'Blockchain and Distributed Ledger Technology',
        description: 'Beyond cryptocurrencies like Bitcoin, blockchain is being explored for various applications such as supply chain management, smart contracts, and decentralized finance (DeFi).'
    },
    {
        icon: <FiUsers />,
        title: 'Smart Contracts',
        description: 'Elevate the efficiency of your transactions with our smart contract solutions. Automate agreements, reduce risks, and streamline processes using blockchain-based contracts.'
    },
    {
        icon: <FiMonitor />,
        title: 'Decentralized Applications (DApps)',
        description: 'Experience the power of decentralized applications. Our DApps leverage blockchain technology to provide secure, transparent, and tamper-resistant solutions for a variety of industries.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
