import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Ad Campaign Management Platforms',
        title: 'Utilize advertising platforms such as Google Ads, Facebook Ads etc'
    },
    {
        image: 'image-2',
        category: 'Ad Targeting Automation',
        title: 'Implement automated targeting based on user demographics, behavior, and interests. '
    },
    {
        image: 'image-3',
        category: 'Dynamic Ads',
        title: 'Leverage dynamic ad creation tools that automatically generate ad content'
    },
    {
        image: 'image-4',
        category: 'A/B Testing Automation',
        title: 'Automate the A/B testing process to experiment with different ad creatives'
    },
    {
        image: 'image-3',
        category: 'Ad Copy Generation',
        title: 'Explore natural language processing (NLP) and machine learning tools to automatically generate ad copy.'
    },
    {
        image: 'image-4',
        category: 'Retargeting Campaigns',
        title: 'Implement automated retargeting campaigns to re-engage users who have previously interacted.'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="#">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href="#">View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to="#"></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;