import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Finance',
        title: 'Explore secure and transparent methods for payments, remittances, and decentralized finance (DeFi) applications.'
    },
    {
        image: 'image-2',
        category: 'Healthcare',
        title: 'Learn how our blockchain solutions are shaping the future of patient records, supply chain management, and medical research.'
    },
    {
        image: 'image-3',
        category: 'Supply Chain',
        title: 'Trace the journey of products, enhance transparency, and mitigate fraud in your supply chain processes.'
    },
    {
        image: 'image-4',
        category: 'Intellectual Property Protection',
        title: 'Blockchain can be used to timestamp and authenticate intellectual property, such as patents, trademarks, and copyrights.'
    },
    {
        image: 'image-3',
        category: 'Tokenization of Assets',
        title: 'Physical and financial assets can be represented digitally on the blockchain through tokenization.'
    },
    {
        image: 'image-4',
        category: 'Cross-Border Payments',
        title: 'Blockchain facilitates faster and more cost-effective cross-border transactions by eliminating the need for traditional banking.'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'Our Blockchain Infrastructure',
        description = 'Explore the real-world impact of our blockchain solutions across different industries. From finance to healthcare, supply chain to entertainment, our Blockchain smart contracts are driving transformative change.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><a href="#">{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href="#">Case Study</a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="link-overlay" to="#"></Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;