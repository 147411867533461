import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About Us',
        description = 'ETPL boasts a comprehensive range of capabilities, combining expertise as coding professionals with a deep proficiency in digital marketing. In essence, ETPL combines coding excellence with digital marketing prowess, providing a holistic approach to meet the evolving needs of businesses in the digital landscape.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Full-Stack Development</h3>
                                                <p>ETPL excels in full-stack development, showcasing proficiency in both front-end and back-end technologies. This encompasses creating dynamic and responsive user interfaces while ensuring robust server-side functionality.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Strategic Campaigns</h3>
                                                <p>ETPL's digital marketing professionals are adept at developing strategic campaigns that align with business objectives. This involves leveraging market insights to create targeted and impactful marketing strategies.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;