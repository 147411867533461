import React, {Component, Fragment} from "react";
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import Slider from "react-slick";
import { innerPageDemo } from "../page-demo/script";
import { testimonialActivation } from "../page-demo/script";
import { ProgressBar } from 'react-bootstrap';

import {
    FaBuilding,
    FaDocker,
    FaReact
} from "react-icons/fa";
import {
    FiSmartphone,
    FiCode,
    FiDownload,
    FiCommand,
    FiHeadphones,
    FiBold,
    FiArrowUp
} from "react-icons/fi";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const featureList = [
    {
        icon: <FaReact/>,
        title: 'Latest Technologies',
        subtitle: 'We implement cutting-edge technology stacks in our projects, ensuring a forward-looking approach.'
    },
    {
        icon: <FiSmartphone/>,
        title: 'Perfectly Responsive',
        subtitle: 'Our designs are perfectly compatible with all devices, offering easy accessibility across various platforms.'
    },
    {
        icon: <FaDocker/>,
        title: 'Codes containerized with Docker',
        subtitle: 'Containerization has become a fundamental technology for modern application development and deployment.'
    },
    {
        icon: <FiCode/>,
        title: 'We engage in Kubernetes',
        subtitle: 'Kubernetes provides a robust and scalable solution for containerized applications, allowing them to run seamlessly'
    },
    {
        icon: <FiDownload/>,
        title: 'Fast Loading Speed',
        subtitle: 'We create applications that not only load quickly but also exhibit low resource usage and scalability.'
    },
    {
        icon: <FiCommand/>,
        title: 'DevOps practices with hands-on Coding',
        subtitle: 'Ensuring a collaborative and cohesive approach to software development and operational processes.'
    },
    {
        icon: <FiHeadphones/>,
        title: '24x7 Support System',
        subtitle: 'We are provide 24 hours support for all clients'
    },
    {
        icon: <FaBuilding/>,
        title: 'Enterprise Application Integration',
        subtitle: 'EAI is a process that enables different software applications within an organization to share and exchange data seamlessly.'
    }
]

const singleDemo = [
    {
        url: 'ai-ml',
        imageUrl: 'ai-ml.jpg',
        title: 'AI - ML - Integration',
        description: 'An AI (Artificial Intelligence) and ML (Machine Learning) workflow refers to the series of interconnected tasks and processes involved in the development, training, deployment, and maintenance of machine learning models within the context of artificial intelligence applications.',
        label: 'New'
    },
    {
        url: 'blockchain',
        imageUrl: 'blockchain.jpg',
        title: 'Blockchain — Smart Contracts',
        description: 'At ETPL, we are pioneering the next wave of innovation in blockchain technology. Elevate the efficiency of your transactions with our smart contract solutions. Automate agreements, reduce risks, and streamline processes using blockchain-based smart contracts.',
        label: 'New'
    },
    {
        url: 'kubernetes',
        imageUrl: 'kubernetes.jpg',
        title: 'Orchestrated Automation with Kubernetes',
        description: 'Kubernetes offers numerous advantages, making it a popular choice for container orchestration and management in modern IT environments. Kubernetes automates the deployment, scaling, and management of containerized applications, ensuring efficient orchestration and coordination of container instances.',
        label: 'New'
    },
    {
        url: 'marketing-automation',
        imageUrl: 'marketing.jpg',
        title: 'Marketing Automation',
        description: 'Marketing automation is a powerful strategy that leverages technology to streamline, automate, and measure marketing tasks and workflows. It involves using software platforms and tools to efficiently manage and nurture leads, personalize customer interactions, and optimize marketing efforts across various channels. ',
        label: ''
    },
    {
        url: 'personal-portfolio',
        imageUrl: 'personal-portfolio.png',
        title: 'Personal Portfolio',
        description: 'Its a personal portfolio tamplate thats can be used your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: ''
    },
    {
        url: 'creative-agency',
        imageUrl: 'creative-agency.png',
        title: 'Creative Agency',
        description: 'Its a modern design with all realted features for creative agencies company.',
        label: ''
    },
    {
        url: 'corporate-business',
        imageUrl: 'corporate-business.png',
        title: 'Corporate Business',
        description: 'Corporate Business is build for new or any startup business company. It has available all section for corporate business agency company and others business purpose.',
        label: 'Hot'
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        description: 'A interior design comes for new interior agency company. Our can hire your clint by using this template. It has landing page feature available.',
        label: 'New'
    },
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'Hot'
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: ''
    },

    {
        url: 'creative-landing',
        imageUrl: 'creative-landing.png',
        title: 'Creative Agency 02 — One Page',
        description: 'Added creative agency landing page.When you need creative agency landing page you can be use it for your creative agency, Digital agency landing page page.',
        label: ''
    },

    {
        url: 'creative-portfolio',
        imageUrl: 'creative-portfolio.png',
        title: 'Creative Portfolio',
        description: 'Creative Portfolio is a awesome portfolio website demo. By using this you can be build a website for your portfolio',
        label: ''
    },

    {
        url: 'designer-portfolio',
        imageUrl: 'designer-portfolio.png',
        title: 'Designer Portfolio',
        description: 'Designer POrtfolio is a portfolio website demo for all personal portfolio website. A designer present their works by the designer portfolio home demo..',
        label: 'Hot'
    },
    
   

    {
        url: 'portfolio-home',
        imageUrl: 'portfolio-home.png',
        title: 'Minimal Portfolio',
        description: 'Its a minimal portfolio template. You can be showing your portfolio perfectly by using this template.',
        label: ''
    },

    {
        url: 'digital-agency',
        imageUrl: 'digital-agency.png',
        title: 'Digital Agency',
        description: 'A Digital Agency template is a nice slider animation, faster loading and all features available for agencies.',
        label: ''
    },
    {
        url: 'business',
        imageUrl: 'business.png',
        title: 'Business',
        description: 'It has available all kinds of features for a Business corporate website.You can be use it for your Business website.',
        label: ''
    },
    {
        url: 'startup',
        imageUrl: 'startup.png',
        title: 'Startup',
        description: 'A modern and nice design template that can be all startup agencies for all kinds of startup purpose.',
        label: ''
    },
    {
        url: 'paralax',
        imageUrl: 'paralax.png',
        title: 'Paralax Home',
        description: 'Its a nice and awesome paralax background image design.You can be use it for Business, Corporate, Degital Agency and other so on.',
        label: ''
    },
    {
        url: 'studio-agency',
        imageUrl: 'studio-agency.png',
        title: 'Studio Agency',
        description: 'It has been used background video and vedio popup.You can use it for all kinds of agencies studio.',
        label: ''
    
    },
    {
        url: '#comingsoon',
        imageUrl: 'coming-soon.png',
        title: 'Coming Soon',
        description: 'More Feature are Coming Soon',
        label: ''
    },
    {
        url: '#comingsoon',
        imageUrl: 'coming-soon.png',
        title: 'Coming Soon',
        description: 'More Feature are Coming Soon',
        label: ''
    }
]

const agencyDemo = [
    {
        url: 'interactive-agency',
        imageUrl: 'interactive-agency.png',
        title: 'Interactive Agency',
        description: 'Its a modern design with all realted features for creative agencies company.',
        label: 'New'
    },
    {
        url: 'main-demo',
        imageUrl: 'demo-home.png',
        title: 'Main Demo',
        description: 'Our Template is perfect for creative agency.All agencies use this template for all purpose.',
        label: ''
    },
    {
        url: 'creative-agency',
        imageUrl: 'creative-agency.png',
        title: 'Creative Agency',
        description: 'Its a modern design with all realted features for creative agencies company.',
        label: ''
    },
    {
        url: 'corporate-business',
        imageUrl: 'corporate-business.png',
        title: 'Corporate Business',
        description: 'Corporate Business is build for new or any startup business company. It has available all section for corporate business agency company and others business purpose.',
        label: 'Hot'
    },
    {
        url: 'home-particles',
        imageUrl: 'homeparticles.png',
        title: 'Home Particle — Landing Page',
        description: 'Home particle has most feature is particles animation.It can be used for your creative agency, Business agency, Digital agency and so on, And it has Landing page added.',
        label: ''
    },
    {
        url: 'digital-agency',
        imageUrl: 'digital-agency.png',
        title: 'Digital Agency',
        description: 'A Digital Agency template is a nice slider animation, faster loading and all features available for agencies.',
        label: ''
    },
    {
        url: 'creative-portfolio',
        imageUrl: 'creative-portfolio.png',
        title: 'Creative Portfolio',
        description: 'Creative Portfolio is a awesome portfolio website demo. By using this you can be display your portfolio and your companies project',
        label: 'New'
    },
    {
        url: 'studio-agency',
        imageUrl: 'studio-agency.png',
        title: 'Studio Agency',
        description: 'It has been used background video and vedio popup.You can use it for all kinds of agencies studio.',
        label: ''
    },
    {
        url: 'dark-main-demo',
        imageUrl: 'dark-main-demo.png',
        title: 'Main Demo — Dark Version',
        description: 'Our Template is perfect for creative agency.All agencies use this template for all purpose.',
        label: 'New'
    },
    {
        url: 'creative-landing',
        imageUrl: 'creative-landing.png',
        title: 'Creative Agency 02 — One Page',
        description: 'Added creative agency landing page.When you need creative agency landing page you can be use it for your creative agency, Digital agency landing page page.',
        label: ''
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        description: 'A interior design comes for new interior agency company. Our can hire your clint by using this template. It has landing page feature available.',
        label: 'New'
    },
    {
        url: 'paralax',
        imageUrl: 'paralax.png',
        title: 'Paralax Home',
        description: 'Its a nice and awesome paralax background image design.You can be use it for Business, Corporate, Degital Agency and other so on.',
        label: ''
    },
]

const busenessDemo = [
    {
        url: 'business',
        imageUrl: 'business.png',
        title: 'Business',
        description: 'It has available all kinds of features for a Business corporate website.You can be use it for your Business website.',
        label: ''
    },
    {
        url: 'startup',
        imageUrl: 'startup.png',
        title: 'Startup',
        description: 'A modern and nice design template that can be all startup agencies for all kinds of startup purpose.',
        label: ''
    },
    {
        url: 'corporate-business',
        imageUrl: 'corporate-business.png',
        title: 'Corporate Business',
        description: 'Corporate Business is build for new or any startup business company. It has available all section for corporate business agency company and others business purpose.',
        label: 'Hot'
    },
    {
        url: 'creative-portfolio',
        imageUrl: 'creative-portfolio.png',
        title: 'Creative Portfolio',
        description: 'Creative Portfolio is a awesome portfolio website demo. By using this you can be display your portfolio and your companies project',
        label: 'New'
    },
    {
        url: 'home-particles',
        imageUrl: 'homeparticles.png',
        title: 'Home Particle — Landing Page',
        description: 'Home particle has most feature is particles animation.It can be used for your creative agency, Business agency, Digital agency and so on, And it has Landing page added.',
        label: ''
    },
    {
        url: 'studio-agency',
        imageUrl: 'studio-agency.png',
        title: 'Studio Agency',
        description: 'It has been used background video and vedio popup.You can use it for all kinds of agencies studio.',
        label: ''
    },
    {
        url: 'paralax',
        imageUrl: 'paralax.png',
        title: 'Paralax Home',
        description: 'Its a nice and awesome paralax background image design.You can be use it for Business, Corporate, Degital Agency and other so on.',
        label: ''
    },
]

const portfolioList = [
    {
        url: 'personal-portfolio',
        imageUrl: 'personal-portfolio.png',
        title: 'Personal Portfolio',
        description: 'Its a personal portfolio tamplate thats can be used your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: ''
    },
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'Hot'
    },
    {
        url: 'designer-portfolio',
        imageUrl: 'designer-portfolio.png',
        title: 'Designer Portfolio',
        description: 'Designer Portfolio is a portfolio website demo for all personal portfolio website. A designer present their works by the designer portfolio home demo..',
        label: 'Hot'
    },

    {
        url: 'creative-portfolio',
        imageUrl: 'creative-portfolio.png',
        title: 'Creative Portfolio',
        description: 'Creative Portfolio is a awesome portfolio website demo. By using this you can be display your portfolio and your companies project',
        label: 'New'
    },
    {
        url: 'portfolio-home',
        imageUrl: 'portfolio-home.png',
        title: 'Minimal Portfolio',
        description: 'Its a minimal portfolio template. You can be showing your portfolio perfectly by using this template.',
        label: ''
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'New'
    },
    


]

const landingPage = [
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'Hot'
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'New'
    },
    {
        url: 'creative-landing',
        imageUrl: 'creative-landing.png',
        title: 'Creative Agency 02 — One Page',
        description: 'Added creative agency landing page.When you need creative agency landing page you can be use it for your creative agency, Digital agency landing page page.',
        label: 'New'
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        description: 'A interior design comes for new interior agency company. Our can hire your clint by using this template. It has landing page feature available.',
        label: 'New'
    },
    {
        url: 'home-particles',
        imageUrl: 'homeparticles.png',
        title: 'Home Particle — Landing Page',
        description: 'Home particle has most feature is particles animation.It can be used for your creative agency, Business agency, Digital agency and so on, And it has Landing page added.',
        label: ''
    },
]

const newDemoList = [
    {
        url: 'interactive-agency',
        imageUrl: 'interactive-agency.png',
        title: 'Interactive Agency',
        description: 'Its a modern design with all realted features for creative agencies company.',
        label: 'New'
    },
    {
        url: 'dark-portfolio-landing',
        imageUrl: 'dark-personal-portfolio-landing.png',
        title: 'Personal Portfolio 02 — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'Hot'
    },
   
    {
        url: 'corporate-business',
        imageUrl: 'corporate-business.png',
        title: 'Corporate Business',
        description: 'Corporate Business is build for new or any startup business company. It has available all section for corporate business agency company and others business purpose.',
        label: 'Hot'
    },
    {
        url: 'interior-landing',
        imageUrl: 'interior-design.png',
        title: 'Interior Design',
        description: 'A interior design comes for new interior agency company. Our can hire your clint by using this template. It has landing page feature available.',
        label: 'New'
    },
    {
        url: 'creative-landing',
        imageUrl: 'creative-landing.png',
        title: 'Creative Agency 02 — One Page',
        description: 'Added creative agency landing page.When you need creative agency landing page you can be use it for your creative agency, Digital agency landing page page.',
        label: 'New'
    },
    {
        url: 'portfolio-landing',
        imageUrl: 'personal-portfolio-landing.png',
        title: 'Personal Portfolio — One Page',
        description: 'It comes with landing page feature. This tamplate you can be used for your Personal useses,  Freelancer, Designer, and Developer etc.',
        label: 'New'
    },
  
]

const innerDemo = [
    {
        url: 'service',
        imageUrl: 'service.png',
        title: 'Service',
    },
    {
        url: 'service-details',
        imageUrl: 'service-details.png',
        title: 'Service Details',
    },
    {
        url: 'about',
        imageUrl: 'about.png',
        title: 'About',
    },
    {
        url: 'blog',
        imageUrl: 'blog-list.png',
        title: 'Blog List',
    },
    {
        url: 'blog-details',
        imageUrl: 'blog-details.png',
        title: 'Blog Details',
    },
    {
        url: 'portfolio',
        imageUrl: 'portfolio.png',
        title: 'Portfolio',
    },
    {
        url: 'portfolio-details',
        imageUrl: 'portfolio-details.png',
        title: 'Portfolio Details',
    },
    {
        url: 'contact',
        imageUrl: 'contact.png',
        title: 'Contact',
    },
    {
        url: '404',
        imageUrl: '404.png',
        title: '404 Page',
    },

]

class Demo extends Component {
    render() {
        return (
            <Fragment>
                {/* Start Banner Area  */}
                <div className="prv-banner-wrapper"
                     style={{backgroundImage: `url("assets/images/preview/preview-bg.jpg")`}}>
                    <div className="container-fluid">
                        <div className="plr--120">
                            <div className="row">
                                <div className="col-lg-8 col-xl-5">
                                    <div className="inner">
                                        <div className="logo text-left">
                                            <a href="/">
                                                <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo Images"/>
                                            </a>
                                        </div>
                                        <p className="title">Ready to embark on coding journey with ETPL? <br/>Elevate your code. Empower your future. Choose ETPL.</p>
                                        <div className="purshase-btn">
                                            <a href="/contact" target="_blank" className="rn-button-style--2 btn-solid">Explore</a>
                                            <a href="#demo" className="rn-button-style--2 btn-border rn-btn-dark border-opacity-half">Technologies</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fullscreen empty-div gradient-overlay"></div>
                {/* End Banner Area  */}

                {/* Start Preview Main Wrapper */}
                <div className="main-wrapper" id="demo">

                    {/* Start Main Demo Area  */}
                    <div className="home-demo-area bg_color--1 ptb--120">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30">
                                        <h2 className="theme-gradient">Technologies & Services</h2>
                                        <p>Tailored solutions crafted to meet your unique business requirements. <br/>Our team of seasoned developers specializes in creating scalable infrastructure that grows with your business.</p>
                                    </div>
                                </div>
                            </div>
                            <Tabs>
                                <div className="row text-center">
                                    <div className="col-lg-12">
                                        <div className="tablist-inner">
                                            <TabList className="pv-tab-button text-center">
                                                <Tab><span>Solutions</span></Tab>
                                                <Tab><span>Technologies</span></Tab>
                                                <Tab><span>Containerization</span></Tab>
                                                <Tab><span>Designs</span></Tab>
                                                <Tab><span>Dev-Ops</span></Tab>
                                                <Tab><span>Marketing</span></Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                </div>
                                
                                <TabPanel className="row">
                                    {singleDemo.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {agencyDemo.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {busenessDemo.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {portfolioList.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {landingPage.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                <TabPanel className="row">
                                    {newDemoList.map((value , index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                    <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                    <h3 className="title">{value.title}</h3>
                                                    <p className="info">{value.description}</p>
                                                    {value.label ? <div className="label-new"><span>{value.label}</span></div> : ''}
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                            </Tabs>

                        </div>
                    </div>
                    {/* End Main Demo Area  */}

                    {/* Start Main Demo  */}
                    <div className="home-demo-area bg_color--3 pt--120 pb--130 pt_md--80 pb_md--90 pt_sm--80 pb_sm--90">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30">
                                        <h2 className="theme-gradient">Web Development</h2>
                                        <p>From sleek and responsive websites to powerful web applications, we leverage <br/>the latest technologies to deliver an unparalleled online experience for your users.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row row--0">
                                <div className="col-lg-12">
                                    <Slider className="inner-demo-activation rn-slick-dot slick-gutter-15" {...innerPageDemo}>
                                        {innerDemo.map((value , index) => (
                                            // Start Single Demo
                                            <div key={index} className="single-slide">
                                                <div className="single-demo">
                                                    <a target="_blank" rel="noopener noreferrer" href={`/${value.url} `}>
                                                        <img src={`/assets/images/preview/${value.imageUrl}`} alt="Creative Agency Images"/>
                                                        <h3 className="title">{value.title}</h3>
                                                    </a>
                                                </div>
                                            </div>
                                            // End Single Demo
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Main Demo  */}

                    {/* Start Feature Area  */}
                    <div className="prv-feature service-area bg_color--7 ptb--120">
                        <div className="wrapper plr--120">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30 pb_sm--0 pb_md--0">
                                        <h2 className="theme-gradient"><span>Feature Rich Interfaces</span></h2>
                                        <p className="text-white">Tailoring content and messaging based on user preferences, behavior, and demographic information.<br/>Enhances the relevance of marketing communications.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* Start Single Feature  */}
                                {featureList.map((value, i) => (
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                                        <div className="service service__style--4">
                                            <div className="icon">
                                                {value.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{value.title}</h3>
                                                <p className="subtitle">{value.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* End Single Feature  */}
                            </div>
                            
                        </div>
                    </div>
                    {/* End Feature Area  */}

                    <div className="gt-matrik-score-area ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row row--35">
                                
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="inner">
                                        <h2 className="title">Continuous Integration</h2>
                                        <p>CI/CD (Continuous Integration/Continuous Deployment) is a set of best practices in software development aimed at automating the processes of integrating code changes and deploying applications to production. It involves the use of automated testing, continuous integration of code changes, and continuous deployment of new features or updates.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                                    <div className="progress-wrapper">
                                        <h3 className="heading">CI/CD Advantages</h3>
                                        <div className="rn-progress-bar progress-bar--3 large-bar">
                                            <div className="single-progress">
                                                <h6 className="title">Deployment Automation</h6>
                                                <ProgressBar now={100} />
                                                <span className="label">100%</span>
                                            </div>

                                            <div className="single-progress">
                                                <h6 className="title">Collaborative Development</h6>
                                                <ProgressBar now={100} />
                                                <span className="label">100%</span>
                                            </div>

                                            <div className="single-progress">
                                                <h6 className="title">Monitoring and Rollback</h6>
                                                <ProgressBar now={100} />
                                                <span className="label">100%</span>
                                            </div>

                                        </div>   
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="shape-images">
                            <img src="/assets/images/icons/wave-icon.png" alt="Wave Images" />
                        </div>
                    </div>

                    {/* Start Responsive Device Area  */}
                    <div className="responsive-device-area bg_image bg_image--34">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-xl-6 offset-xl-6">
                                    <div className="inner">
                                        <div className="section-title">
                                            <h2 className="title theme-gradient">Digital Marketing Automation</h2>
                                            <p>Businesses seeking to enhance and streamline their marketing efforts can benefit from the numerous advantages of digital marketing automation.</p>
                                        </div>
                                        <div className="responsive-layout-mokup">
                                            <div className="mokup-list">
                                                <div className="icon">
                                                    <img src="/assets/images/icons/phone.png" alt="icon Images" />
                                                </div>
                                                <h6>Phone</h6>
                                            </div>
                                            <div className="mokup-list">
                                                <div className="icon">
                                                    <img src="/assets/images/icons/tablet.png" alt="icon Images" />
                                                </div>
                                                <h6>Tablet</h6>
                                            </div>
                                            <div className="mokup-list">
                                                <div className="icon">
                                                    <img src="/assets/images/icons/desktop.png" alt="icon Images" />
                                                </div>
                                                <h6>Desktop</h6>
                                            </div>
                                        </div>
                                        <p className="subtitle">Enable seamless interaction for customers across different facets of the website</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Responsive Device Area  */}

                     {/* Start Faq Area */}
                    <div className="rn-accordion-area pv-feaq-area bg_color--1 ptb--120">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="section-title text-left mb--30">
                                        <h2 className="title">Have a <span className="theme-color">Question?</span>
                                        </h2>
                                        <p>Check out our FAQ section to see if we can help.</p>
                                    </div>
                                    <div className="faq-area">
                                        <Accordion className="accodion-style--1" preExpanded={'0'}>
                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        1. What cloud IaaS services does your agency provide for web development?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>We specialize in leveraging cloud Infrastructure as a Service (IaaS) solutions for scalable and flexible website development, utilizing platforms such as AWS, Azure, or Google Cloud.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        2. How does cloud IaaS impact website development timelines?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>Cloud IaaS allows for quicker provisioning of resources, potentially reducing website development timelines. The exact timeframe depends on the project's complexity and requirements.</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        3. How does cloud IaaS benefit digital marketing efforts?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div>
                                                        <p>
                                                        Cloud IaaS enhances digital marketing by providing scalable infrastructure for hosting websites, handling increased traffic during campaigns, and facilitating data analytics for marketing insights.
                                                        </p>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        4. Do you use cloud IaaS for social media management and content distribution?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                    Yes, we leverage cloud IaaS for efficient social media management and content distribution, ensuring reliable and scalable services to engage your audience.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        5. Can cloud IaaS improve the performance of my website for SEO purposes?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                    Absolutely, cloud IaaS enhances website performance, contributing to better SEO rankings by providing faster loading times and improved user experience.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        6. How does cloud IaaS impact the measurement of marketing campaign success?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                    Cloud IaaS enables the deployment of analytics tools to measure the success of marketing campaigns, providing real-time data on website traffic, conversions, and overall campaign performance.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        7. Which cloud IaaS features are crucial for marketing-related workloads?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                    Key cloud IaaS features for marketing include scalable compute resources, content delivery networks (CDN), and data analytics tools to handle diverse marketing workloads effectively.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        8. Can you migrate an existing website to a cloud IaaS environment?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                    Yes, we offer website migration services to move your existing site to a cloud IaaS environment, ensuring scalability, reliability, and improved performance.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-12 mt_md--40 mt_sm--40">
                                    <div className="card-box text-left">
                                        <div className="service">
                                            <div className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg></div>
                                            <div className="content">
                                                <h3 className="title">Online Documentation</h3>
                                                <p>Well organized and up to date</p>
                                                <a href="#" className="rbt-button rn-button-style--2 rn-btn-small-2 btn-solid-border"><span className="button-text">Online Documentation</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-box text-left mt--40 support">
                                        <div className="service">
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-headphones"><path d="M3 18v-6a9 9 0 0 1 18 0v6"></path><path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path></svg>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">Dedicated Support</h3>
                                                <p>Need support ? Submit a ticket. We will be happy to assist you.</p>
                                                <a href="/contact" className="rbt-button rn-button-style--2 rn-btn-small-2 btn-solid-border"><span className="button-text">Get Support</span></a>
                                                <ul className="liststyle">
                                                    <li><span>Support Time:</span> Monday – Friday</li>
                                                    <li><span>Response Time:</span> Maximum 24 hours</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>        
                    </div>
                     {/* Start Faq Area */}
                                    

                    
                    {/* Start Gallery Area  */}
                    <div className="rn-gallery-area bg_image--35 pt--120 pb--70">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center text-white">
                                    <h2 className="title theme-gradient">The Coding <br />Technologies</h2>
                                    <p className="text-white">We analyze your requirements and develop exceptional code solutions to simplify your workflows.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mt--50 mt_sm--20">
                            <div className="col-lg-12">
                                <div className="thumbnai">
                                    <img src="assets/images/bg/gallery.jpg" alt="Gallery Images" />
                                </div>
                            </div>
                        </div>
                    </div>   
                    {/* End Gallery Area  */}   


                    <div className="testimonial-area bg_color--1 pt--120 pb--150 pt_md--80 pb_md--110 pt_sm--80 pb_sm--110">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2 className="title theme-gradient">Our Customer feedback</h2>
                                        <p>Our Fantastic Customers Reviews</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Slider className="mt--30 mt_sm--5 mt_md--5 testimonial-activation rn-slick-dot slick-gutter-15" {...testimonialActivation}>

                                        {/* Start Single Testimonial  */}
                                        <div className="single-testimonial">
                                            <div className="pv-testimonial">
                                                <div className="inner">
                                                    <div className="inner-top">
                                                        <div className="thumbnail">
                                                            <img src="/assets/images/icons/download.png" alt="Download Images" />
                                                        </div>
                                                        <div className="info">
                                                            <h4 className="title">The4</h4>
                                                            <p>@The4</p>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Web Development</h5>
                                                        <p>ETPL's web development team demonstrated a remarkable blend of creativity and technical expertise. They took the time to understand our business needs, resulting in a website that not only looks sleek and modern but is also highly functional. The attention to detail in both design and functionality surpassed our expectations.</p>
                                                        <div className="rating">
                                                            <img src="/assets/images/icons/rating.png" alt="Rating Images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Testimonial  */}
                                        

                                        {/* Start Single Testimonial  */}
                                        <div className="single-testimonial">
                                            <div className="pv-testimonial">
                                                <div className="inner">
                                                    <div className="inner-top">
                                                        <div className="thumbnail">
                                                            <img src="/assets/images/icons/download.png" alt="Download Images" />
                                                        </div>
                                                        <div className="info">
                                                            <h4 className="title">wimm-x</h4>
                                                            <p>@wimm-x</p>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Digital Marketing</h5>
                                                        <p>The digital marketing strategies employed by ETPL were nothing short of brilliant. From SEO optimization that significantly boosted our online visibility to engaging social media campaigns, they showcased a deep understanding of the digital landscape. The measurable results were evident in increased traffic and enhanced brand recognition.</p>
                                                        <div className="rating">
                                                            <img src="/assets/images/icons/rating.png" alt="Rating Images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Testimonial  */}
                                        

                                        {/* Start Single Testimonial  */}
                                        <div className="single-testimonial">
                                            <div className="pv-testimonial">
                                                <div className="inner">
                                                    <div className="inner-top">
                                                        <div className="thumbnail">
                                                            <img src="/assets/images/icons/download.png" alt="Download Images" />
                                                        </div>
                                                        <div className="info">
                                                            <h4 className="title">Cloudcapped</h4>
                                                            <p>@Cloudcapped</p>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Cloud Integration</h5>
                                                        <p>ETPL's proficiency in cloud integration played a pivotal role in streamlining our operations. The transition to cloud services was seamless, and their team ensured that our data remained secure and accessible. The scalability of the integrated cloud solutions provided by ETPL has proven invaluable as our business continues to grow.</p>
                                                        <div className="rating">
                                                            <img src="/assets/images/icons/rating.png" alt="Rating Images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Testimonial  */}

                                        {/* Start Single Testimonial  */}
                                        <div className="single-testimonial">
                                            <div className="pv-testimonial">
                                                <div className="inner">
                                                    <div className="inner-top">
                                                        <div className="thumbnail">
                                                            <img src="/assets/images/icons/download.png" alt="Download Images" />
                                                        </div>
                                                        <div className="info">
                                                            <h4 className="title">piotrgrz</h4>
                                                            <p>@piotrgrz</p>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Overall Experience</h5>
                                                        <p>What sets ETPL apart is their commitment to delivering holistic solutions. The synergy between their web development, digital marketing, and cloud integration teams is evident in the cohesive and integrated approach they bring to projects. Communication was transparent throughout, and their dedication to client satisfaction was evident at every stage.
                                                        </p>
                                                        <div className="rating">
                                                            <img src="/assets/images/icons/rating.png" alt="Rating Images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Testimonial  */}

                                        {/* Start Single Testimonial  */}
                                        <div className="single-testimonial">
                                            <div className="pv-testimonial">
                                                <div className="inner">
                                                    <div className="inner-top">
                                                        <div className="thumbnail">
                                                            <img src="assets/images/icons/download.png" alt="Download Images" />
                                                        </div>
                                                        <div className="info">
                                                            <h4 className="title">xBoot</h4>
                                                            <p>@xBoot</p>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Seamless Collaboration, Remarkable Results!</h5>
                                                        <p>The web development team crafted a visually stunning and user-friendly website, complemented perfectly by the strategic digital marketing campaigns that significantly boosted our online presence. The cloud integration services provided a robust foundation for our expanding business. Highly recommended for anyone seeking comprehensive and effective solutions.
                                                        </p>
                                                        <div className="rating">
                                                            <img src="assets/images/icons/rating.png" alt="Rating Images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Testimonial  */}

                                        {/* Start Single Testimonial  */}
                                        <div className="single-testimonial">
                                            <div className="pv-testimonial">
                                                <div className="inner">
                                                    <div className="inner-top">
                                                        <div className="thumbnail">
                                                            <img src="assets/images/icons/download.png" alt="Download Images" />
                                                        </div>
                                                        <div className="info">
                                                            <h4 className="title">mindcycle001</h4>
                                                            <p>@mindcycle001</p>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h5>Strategic Partners for Growth!</h5>
                                                        <p>Choosing ETPL for web development, digital marketing, and cloud integration was a strategic decision that has paid off immensely. The website they developed not only aligns with our business goals but also stands out in terms of aesthetics and functionality. Their digital marketing team demonstrated a deep understanding of our target audience, resulting in impactful campaigns that drove conversion rates.</p>
                                                        <div className="rating">
                                                            <img src="assets/images/icons/rating.png" alt="Rating Images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Testimonial  */}

                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>          


                    {/* Start Footer Area  */}
                    <footer className="call-to-action-wrapper text-white-wrapper call-to-action ptb--120 with-particles">
                        <div className="frame-layout__particles">
                            <Particles className="particle"  
                                options={{
                                    style:{
                                        position: "absolute"
                                    },
                                    fpsLimit: 100,
                                    interactivity: {
                                    detectsOn: "canvas",
                                    events: {
                                        onClick: {
                                        enable: true,
                                        mode: "push",
                                        },
                                        onHover: {
                                        enable: true,
                                        mode: "repulse",
                                        },
                                        resize: true,
                                    },
                                    modes: {
                                        bubble: {
                                            distance: 100,
                                            duration: 2,
                                            opacity: 0.8,
                                            size: 10,
                                            color: "#ffffff",
                                        },
                                        push: {
                                        quantity: 4,
                                        },
                                        repulse: {
                                            distance: 100,
                                            duration: 0.4,
                                            color: "#ffffff",
                                        },
                                    },
                                    },
                                    particles: {
                                    color: {
                                        value: "#ffffff",
                                    },
                                    links: {
                                        color: "#ffffff",
                                        distance: 150,
                                        enable: true,
                                        opacity: 0.5,
                                        width: 1,
                                    },
                                    collisions: {
                                        enable: true,
                                    },
                                    move: {
                                        direction: "none",
                                        enable: true,
                                        outMode: "bounce",
                                        random: false,
                                        speed: 6,
                                        straight: false,
                                       
                                    },
                                    number: {
                                        density: {
                                            enable: true,
                                            value_area: 2000,
                                        },
                                        value: 80,
                                    },
                                    opacity: {
                                        value: 0.5,
                                    },
                                    shape: {
                                        type: "circle",
                                    },
                                    size: {
                                        random: true,
                                        value: 5,
                                    },
                                    },
                                    detectRetina: true,
                                }}
                            />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner text-center">
                                        <span>Elevate your code. Empower your future. Choose ETPL.</span>
                                        <h2>Get in Touch with us Today</h2>
                                        <a className="rn-button-style--2" href="/contact"><span>Contact Now</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                    {/* End Footer Area  */}
                </div>
                {/* End Preview Main Wrapper */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiArrowUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Demo;